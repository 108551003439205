<template>
  <div class="dynamic-tables">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget>
      <DataTable
        class="p-datatable-sm"
        :value="wallboxes"
        :paginator="true"
        :filters="filters"
        :loading="loading"
        :scrollable="true"
        scrollHeight="650px"
        :rows="1000"
        dataKey="id"
        :rowHover="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[25, 50, 100, 1000]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
      >
        <template #header>
          <div class="table-header">
            <div class="table-header-left">
              <h1 class="page-title">{{ $t('wallboxes') }}</h1>
            </div>
            <div class="table-header-right">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText type="text" v-model="filters['global']" :placeholder="$t('search')" />
              </span>
              <div class="button-right">
                <router-link class="btn btn-success mb-2 right" :to="{ name: 'WallboxCreatePage' }">
                  <i class="fa fa-plus-circle mr-2"></i>
                  {{ $t('wallboxCreate') }}
                </router-link>
              </div>
            </div>
          </div>
        </template>
        <template #empty>
          {{ $t('no_data_found') }}
        </template>
        <template #loading>
          {{ $t('loading') }}
        </template>

        <Column field="number" header="#" sortable filterMatchMode="contains">
          <template #body="slotProps">
            <router-link
              :to="{
                name: 'WallboxEditPage',
                params: { wallboxNumber: slotProps.data.number },
              }"
              >{{ slotProps.data.number.toString().padStart(4, '0') }}</router-link
            >
          </template>
          <template #filter>
            <InputText type="text" v-model="filters['number']" class="p-column-filter" />
          </template>
        </Column>

        <Column
          field="manufacturer"
          :header="$t('manufacturer')"
          sortable
          filterMatchMode="contains"
        >
          <template #filter>
            <InputText type="text" v-model="filters['manufacturer']" class="p-column-filter" />
          </template>
        </Column>

        <Column field="type" :header="$t('type')" sortable filterMatchMode="contains">
          <template #filter>
            <InputText type="text" v-model="filters['type']" class="p-column-filter" />
          </template>
        </Column>

        <Column
          field="maxNetOutputPowerFormatted"
          :header="$t('maxNetOutputPower')"
          sortable
          filterMatchMode="startsWith"
        >
          <template #filter>
            <InputText
              type="text"
              v-model="filters['maxNetOutputPowerFormatted']"
              class="p-column-filter"
            />
          </template>
        </Column>

        <Column
          field="maxNetInputPowerFormatted"
          :header="$t('maxNetInputPower')"
          sortable
          filterMatchMode="startsWith"
        >
          <template #filter>
            <InputText
              type="text"
              v-model="filters['maxNetInputPowerFormatted']"
              class="p-column-filter"
            />
          </template>
        </Column>

        <Column
          field="minChargingPowerFormatted"
          :header="$t('minChargingPower')"
          sortable
          filterMatchMode="startsWith"
        >
          <template #filter>
            <InputText
              type="text"
              v-model="filters['minChargingPowerFormatted']"
              class="p-column-filter"
            />
          </template>
        </Column>
        <Column
          field="maxChargingPowerFormatted"
          :header="$t('maxChargingPower')"
          sortable
          filterMatchMode="startsWith"
        >
          <template #filter>
            <InputText
              type="text"
              v-model="filters['maxChargingPowerFormatted']"
              class="p-column-filter"
            />
          </template>
        </Column>
        <Column
          field="activePowerControllable"
          :header="$t('activePowerControllable')"
          sortable
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <b-badge :variant="yesNoColor(slotProps.data.activePowerControllable)">
              {{ $t(slotProps.data.activePowerControllable) }}
            </b-badge>
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['activePowerControllable']"
              :options="optionsYesNo()"
              :placeholder="$t('apply_filter')"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <b-badge :variant="yesNoColor(slotProps.option)">
                  {{ $t(slotProps.option) }}
                </b-badge>
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column
          field="chargingType"
          :header="$t('chargingType')"
          sortable
          filterMatchMode="startsWith"
        >
          <template #body="slotProps">
            {{ $t(slotProps.data.chargingType) }}
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['chargingType']"
              :options="getEnumValues('ChargingType')"
              :placeholder="$t('apply_filter')"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                {{ $t(slotProps.option) }}
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column
          field="shiftFactorCosphiType"
          :header="$t('shiftFactorCosphiType')"
          sortable
          filterMatchMode="startsWith"
        >
          <template #body="slotProps">
            {{ $t(slotProps.data.shiftFactorCosphiType) }}
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['shiftFactorCosphiType']"
              :options="getEnumValues('ShiftFactorCosphiType')"
              :placeholder="$t('apply_filter')"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                {{ $t(slotProps.option) }}
              </template>
            </Dropdown>
          </template>
        </Column>
      </DataTable>
    </Widget>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import { getYesNoStateColor } from '@/helpers/colors';
import { yesNo } from '@/helpers/enums';
import Dropdown from 'primevue/dropdown';

export default {
  name: 'WallboxesPage',
  components: {
    Breadcrumbs,
    DataTable,
    Widget,
    InputText,
    Column,
    Dropdown,
  },
  data() {
    return {
      loading: true,
      filters: {},
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'getWallboxes', 'getEnumValues']),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('wallboxes') }];
    },
    wallboxes() {
      const wallboxes = [];
      this.getWallboxes.map((wallbox) => {
        wallbox.maxNetInputPowerFormatted = this.$n(wallbox.maxNetInputPower, 'decimal_triple');
        wallbox.maxNetOutputPowerFormatted = this.$n(wallbox.maxNetOutputPower, 'decimal_triple');
        wallbox.minChargingPowerFormatted = this.$n(wallbox.minChargingPower, 'decimal_triple');
        wallbox.maxChargingPowerFormatted = this.$n(wallbox.maxChargingPower, 'decimal_triple');
        wallboxes.push(wallbox);
      });

      return wallboxes;
    },
  },
  methods: {
    ...mapActions(['fetchWallboxes', 'fetchEnumValues']),
    optionsYesNo() {
      return yesNo();
    },
    yesNoColor(state) {
      return getYesNoStateColor(state);
    },
  },
  async created() {
    await this.fetchWallboxes();
    await this.fetchEnumValues('ChargingType');
    await this.fetchEnumValues('ShiftFactorCosphiType');
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(1),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(1) {
  width: 60px;
  text-align: center;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(n + 4),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(n + 4) {
  width: 10%;
  text-align: center;
}
.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}
/** Fix Dropdown overlay */
::v-deep .p-datatable-scrollable-header {
  overflow: visible;
}
</style>
